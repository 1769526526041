<template>
    <v-expansion-panels
        class="main-menu-accordion"
        variant="accordion"
        :flat="!showBorderLines"
        static
    >
        <MenuItem.define #default="{ item }">
            <v-list-item
                v-if="item.path"
                :to="item.path"
                class="px-2 py-0 my-0"
                :class="cssClassItem"
                :aria-label="item.label"
                role="link"
                exact
            >
                {{ item.label }}
            </v-list-item>
            <v-list-item
                v-else-if="item.url"
                class="px-2 py-0 my-0"
                :class="cssClassItem"
                :aria-label="item.label"
                role="link"
                exact
            >
                <a
                    class="text-anchor text-decoration-none"
                    :href="item.url"
                >
                    {{ item.label }}
                </a>
            </v-list-item>
        </MenuItem.define>

        <template
            v-for="(item, index) in items"
            :key="`main-menu-accordion-parent-${index}`"
        >
            <v-expansion-panel
                :elevation="0"
                bg-color="transparent"
            >
                <v-expansion-panel-title
                    :class="cssClassTitle"
                >
                    {{ item.label }}
                </v-expansion-panel-title>
                <v-expansion-panel-text
                    class="px-0 mx-0"
                >
                    <v-list
                        class="bg-transparent pa-0"
                    >
                        <v-list-item
                            v-if="item.path && !noLinkTitle"
                            :to="item.path"
                            class="px-2 py-0 my-0"
                            :class="cssClassItem"
                            :aria-label="`All ${item.label}`"
                            role="link"
                            exact
                        >
                            All {{ item.label }}
                        </v-list-item>

                        <template
                            v-for="child in item.items || []"
                            :key="`main-menu-accordion-child-${child.id ?? index}`"
                        >
                            <MenuItem.reuse
                                v-if="child.path || child.url"
                                :item="child"
                            ></MenuItem.reuse>

                            <template
                                v-else-if="(!child.path && !child.url) && child.items?.length"
                            >
                                <v-list-item-subtitle
                                    class="font-size-7 opacity-100 font-weight-medium py-4 px-2"
                                >
                                    {{ child.label }}
                                </v-list-item-subtitle>
                                <template
                                    v-for="gchild in child.items || []"
                                    :key="`main-menu-accordion-child-items-${child.id ?? index}`"
                                >
                                    <MenuItem.reuse
                                        :item="gchild"
                                    ></MenuItem.reuse>
                                </template>
                            </template>
                        </template>
                    </v-list>

                </v-expansion-panel-text>
            </v-expansion-panel>
        </template>
    </v-expansion-panels>
</template>

<script lang="ts" setup>
    import type { MenuItem } from '~/types';
    import { createReusableTemplate } from '@vueuse/core';

    withDefaults(
        defineProps<{
            items: Nilable<MenuItem[]>;
            noLinkTitle?: boolean;
            showBorderLines?: boolean;
            cssClassTitle?: Nilish<string>;
            cssClassItem?: Nilish<string>;
        }>(),
        {
            cssClassTitle: 'font-size-6',
            cssClassItem: 'text-body-1'
        }
    );

    const MenuItem = createReusableTemplate<{ item: MenuItem }>();
</script>

<style lang="scss">
.main-menu-accordion {
    .v-expansion-panel-text__wrapper {
        padding-left: 16px;
        padding-right: 16px;
    }
}
</style>
